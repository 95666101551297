import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="LoadTimes Blog" link="https://load-times.com" bg="linear-gradient(to right, #3D348B 0%, #7678ed 100%)" mdxType="ProjectCard">
  Gaming news, opinions, and reviews blog. 
    </ProjectCard>
    <ProjectCard title="Twinfinite Articles" link="https://twinfinite.net/author/iann/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Gaming & entertainment news, reviews, guides, & hype. 
    </ProjectCard>
    <ProjectCard title="Art Station" link="https://inapier.artstation.com/" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  3D graphics, 2D pixel art, & animations.
    </ProjectCard>
    <ProjectCard title="GitHub" link="https://www.github.com/inapier" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Games, mods, or other coding projects. 
    </ProjectCard>
    <ProjectCard title="LinkedIn" link="https://www.linkedin.com/in/iannapier1" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Currently seeking employment in game journalism.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      