import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say hi on `}<a parentName="p" {...{
        "href": "https://twitter.com/SirNapkin"
      }}>{`Twitter`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/iannapier1/"
      }}>{`LinkedIn`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://github.com/inapier"
      }}>{`GitHub`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      