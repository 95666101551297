import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I have a strong passion for games stretching back into my formative years that has shaped and impacted my life in more ways than I care to count. As I am still a student, my field experience is limited, but I have studied the basics of game design and continue my studies during my spare time. I have created small games with Unity and Pygame.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      